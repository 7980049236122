import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';

import wooCommerceReducer from './wooCommerce';
import calculatorReducer from './calculator';
import firebaseReducer from './firebase.duck';
import { loadState } from '../helpers/browserStorage';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const reducers = combineReducers({
  wooCommerce: wooCommerceReducer,
  calculator: calculatorReducer,
  CMS: firebaseReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore(
  {
    reducer: persistedReducer,
    preloadedState: loadState(),
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }
    }),
    devTools: process.env.NODE_ENV !== 'production',
  }
);

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
