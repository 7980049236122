import React, { useCallback, useEffect, useState } from 'react';
import generateClass from '../utils/generateClass';
import { HexColorCode } from '../utils/colors';
import { FeatureType, IWoocommerceProduct } from 'interfaces';
import style from './selectable-feature.module.scss';
const stylePrefix = 'selectable-feature-';


interface SelectableFeatureProps {
  options: string[];
  chosenProduct: IWoocommerceProduct;
  defaultOption: string;
  featureType: FeatureType;
  updateFeatureOnChosenItem: (featureType: FeatureType, value: any) => void;
}

export const SelectableFeature = (props: SelectableFeatureProps): JSX.Element => {
  const currentChosenItem: IWoocommerceProduct | undefined = props.chosenProduct;
  const [activeSize, setActiveSize] = useState("");
  const [activeColor, setActiveColor] = useState("");
  const [activeBoardType, setActiveBoardType] = useState("");
  const [activeLineLength, setActiveLineLength] = useState("");

  useEffect(() => {
    var preselectedValue = props.options.includes(props.defaultOption) ? props.defaultOption : (props.options == null ? "" : props.options[0]);

    if(props.featureType === FeatureType.Size)
      setActiveSize(preselectedValue);
    else if(props.featureType === FeatureType.Color)
      setActiveColor(preselectedValue);
    else if(props.featureType === FeatureType.BoardType)
      setActiveBoardType(preselectedValue);
    else if(props.featureType === FeatureType.LineLength)
      setActiveLineLength(preselectedValue);

    props.updateFeatureOnChosenItem(props.featureType, preselectedValue);
  }, [props.featureType, props.options, props.defaultOption]);



  interface SizeProps {
    options: any[];
    activeSize: string;
    setActiveSize: (size: string) => void;
    updateFeatureOnChosenItem: (featureType: FeatureType, value: any) => void;
  }
  const Size = useCallback((props: SizeProps) => {
    return (
      <div className={style.selectableOptionsContainer}>
        { props.options.map((option: any, i: number) => (
          <div key={'selectable-size-' + i.toString()}
               className={style.selectableOptionOuter} 
               style={{ backgroundColor: props.activeSize === option ? 'var(--dark-blue)' : 'transparent' }}>
            <button
              className={style.selectableOptionInner}
              onClick={() => {
                props.setActiveSize(option as string);
                props.updateFeatureOnChosenItem(FeatureType.Size, option);
              }}
              style={{ color: props.activeSize === option ? 'white' : 'var(--dark-blue)' }}
            >{option}</button>
          </div>
        ))}
      </div>
    )
  }, []);
  

  interface ColorProps {
    options: any[];
    activeColor: string;
    setActiveColor: (color: string) => void;
    updateFeatureOnChosenItem: (featureType: FeatureType, value: any) => void;
  }
  const Color = useCallback((props: ColorProps) => {
    return (
      <div className={generateClass(style, stylePrefix, '-column')}>
        { props.options.length > 0 &&
        <div className={generateClass(style, stylePrefix, '-row')}>
          { props.options.map((option: string, i: number) => (
              <div
                key={'selectable-color-' + i.toString()}
                onClick={() => {
                  props.setActiveColor(option);
                  props.updateFeatureOnChosenItem(FeatureType.Color, option);
                }}
                className={generateClass(style, stylePrefix, '-row-color-option-wrapper')}
                style={{
                  border: `3px solid ${option === props.activeColor || (props.activeColor === "" && option === props.options[0]) ? 'var(--light-blue)' : 'var(--grey)' }`,
                  boxShadow: `${option === props.activeColor || (props.activeColor === "" && option === props.options[0]) ? '3px 3px 5px rgba(200, 200, 200, 0.7)' : '1px 1px 5px rgba(200, 200, 200, 0.7)'}`,
                  backgroundColor: HexColorCode[option as keyof typeof HexColorCode]
                }}
              ></div>
            ))
          }
        </div>
        }
      </div>
    )
  }, [])
  

  interface BoardTypeProps {
    options: any[];
    activeBoardType: string;
    setActiveBoardType: (boardType: string) => void;
    updateFeatureOnChosenItem: (featureType: FeatureType, value: any) => void;
  }
  const BoardType = useCallback((props: BoardTypeProps) => {
    return (
      <div className={style.selectableOptionsContainer}>
        { props.options.map((option: string, i: number) => (
          <div key={'selectable-boardType-' + i.toString()}
               className={style.selectableOptionOuter} 
               style={{ backgroundColor: props.activeBoardType === option ? 'var(--dark-blue)' : 'transparent' }} >
            <button
              className={style.selectableOptionInner}
              onClick={() => {
                props.setActiveBoardType(option);
                props.updateFeatureOnChosenItem(FeatureType.BoardType, option);
              }}
              style={{ color: props.activeBoardType === option ? 'white' : 'var(--dark-blue)' }}
            >{ option }</button>
          </div>
        ))}
      </div>
    )
  }, [])
  

  interface LineLengthProps {
    options: any[];
    activeLineLength: string;
    setActiveLineLength: (lineLength: string) => void;
    updateFeatureOnChosenItem: (featureType: FeatureType, value: any) => void;
  }
  const LineLength = useCallback((props: LineLengthProps) => {
    return (
      <div className={style.selectableOptionsContainer}>
        { props.options.map((option: string, i: number) => (
          <div key={'selectable-lineLength-' + i.toString()}
               className={style.selectableOptionOuter} 
               style={{ backgroundColor: props.activeLineLength === option ? 'var(--dark-blue)' : 'transparent' }} >
            <button
              className={style.selectableOptionInner}
              
              onClick={() => {
                props.setActiveLineLength(option);
                props.updateFeatureOnChosenItem(FeatureType.LineLength, option);
              }}
              style={{ color: props.activeLineLength === option ? 'white' : 'var(--dark-blue)' }}
            >{option}</button>
          </div>
        ))}
      </div>
    )
  }, [])

  switch (props.featureType) {
    case FeatureType.Size:
      if(props.options != null && props.options.length > 0 && currentChosenItem && Object.keys(currentChosenItem).length > 0)
        return <Size options={props.options} activeSize={activeSize} setActiveSize={setActiveSize} updateFeatureOnChosenItem={props.updateFeatureOnChosenItem} />
      break;

    case FeatureType.Color:
      if(props.options != null && props.options.length > 0 && currentChosenItem && Object?.keys(currentChosenItem)?.length > 0)
        return <Color options={props.options} activeColor={activeColor} setActiveColor={setActiveColor} updateFeatureOnChosenItem={props.updateFeatureOnChosenItem} />
      break;

    case FeatureType.BoardType:
      if(props.options != null && props.options.length > 0 && currentChosenItem && Object?.keys(currentChosenItem)?.length > 0)
        return <BoardType options={props.options} activeBoardType={activeBoardType} setActiveBoardType={setActiveBoardType} updateFeatureOnChosenItem={props.updateFeatureOnChosenItem}/>
      break;

    case FeatureType.LineLength:
      if(props.options != null && props.options.length > 0 && currentChosenItem && Object?.keys(currentChosenItem)?.length > 0)
        return <LineLength options={props.options} activeLineLength={activeLineLength} setActiveLineLength={setActiveLineLength} updateFeatureOnChosenItem={props.updateFeatureOnChosenItem}/>  
      break;

    default:
      return <></>;
  }
  return <></>
};