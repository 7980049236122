import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { FunctionComponent } from "react";
import styles from "./welcome-screen.module.scss";
import { getAuth, signInAnonymously } from "firebase/auth";
import { useFirebaseCMSActions } from '../../hooks/firebaseCMS';
import { useWooCommerce, useWooCommerceActions } from '../../hooks/wooCommerce';
const backgroundImage = require("../../assets/images/welcome-background.png");



export const WelcomeScreen: FunctionComponent = () => {
  const navigate = useNavigate();
  const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });
  const { getCMSContent } = useFirebaseCMSActions();
  const { loaded } = useWooCommerce();
  const { getAllProducts } = useWooCommerceActions();
  const auth = getAuth();

  useEffect(() => {
    if (loaded !== true){
      getAllProducts();

      signInAnonymously(auth)
      .then(() => {
        getCMSContent()
      });
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.welcomePageDiv}>
      <img
        className={styles.backgroundImageIcon}
        alt=""
        src={backgroundImage}
      />

      <div className={styles.transparancyDiv}>
        <div className={styles.transparancyDiv1} />
      </div>

      <div className={styles.copyDiv} id="welcome-text">
        <h1 className={styles.helloKiteLover} id="welcome-text-h1">
          Hello kite lover!
        </h1>
        <div
          className={styles.readyToGetYourKitePlan}
          id="welcome-text-description"
        >
          Ready to get your kite plan?
        </div>
      </div>

      <div className={styles.frameDiv}>
        <button className={styles.buttonPrimary} onClick={() => handleNavigation("/chooseType", { context: { type: null } })}>
          <div className={styles.letsGoDiv}>Let’s go!</div>
        </button>
        <button className={styles.buttonSecondary} onClick={() => handleNavigation("/howItWorks1", {})}>
          <div className={styles.seeHowItWorks}>see how it works</div>
        </button>
      </div>
    </div>
  );
};