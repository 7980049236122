import * as actionTypes from './actionTypes'
import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { IWoocommerceProduct } from 'interfaces';
import { mapObjectToIWoocommerceVariation } from 'store/data-mappings';
import { findVariationForProductSelection } from 'utils/product-variation';

interface CalculatorState {
  chosenProducts: IWoocommerceProduct[];
  currentFee: number;
  currentMonthsSelection: number;
}

const initialState: CalculatorState = {
  chosenProducts: [] as IWoocommerceProduct[],
  currentFee: 0,
  currentMonthsSelection: 24,
}


const manipulators = {
  addItemToSelectedProducts: (state: CalculatorState, action: PayloadAction<IWoocommerceProduct>) => {
    let nextSelectionId = 1;
    if (state.chosenProducts != null && state.chosenProducts.length !== 0){
      nextSelectionId = Math.max(...state.chosenProducts.map(elem => elem.selectionId)) + 1;
    }

    if (action.payload.productVariations != null){
      const selectedProductVariation = findVariationForProductSelection(action.payload.productVariations, action.payload);
      if (selectedProductVariation != null) {
        state.chosenProducts = [
          ...state.chosenProducts,
          {
            ...action.payload,
            productVariations: action.payload.productVariations,
            selectedProductVariation: selectedProductVariation,
            selectionId: nextSelectionId,
          }
        ];
      }
      return;
    } 
    state.chosenProducts = [
      ...state.chosenProducts,
      {
        ...action.payload,
        selectionId: nextSelectionId,
      }
    ];
  },

  updateItemInSelectedProducts: (state: CalculatorState, action: PayloadAction<IWoocommerceProduct>) => {
    if (state.chosenProducts.length !== 0){
      var productIndex = state.chosenProducts.findIndex(elem => elem.selectionId === action.payload.selectionId);
      if (productIndex !== -1){
        state.chosenProducts[productIndex] = { 
          ...action.payload,
        };
      }
    }
  },

  removeItemFromSelectedProducts: (state: CalculatorState, action: PayloadAction<{ productId: number, selectionId: number}>) => {
    state.chosenProducts = [...state.chosenProducts.filter(product => product.selectionId !== action.payload.selectionId)]
  },

  updateVariationsInChosenProducts: (state: CalculatorState, action: PayloadAction<{productId: number, variationsData: any[]}>) => {
    // update product variations for product in chosenProducts if it exists
    if (state.chosenProducts.length !== 0){
      var productIndex = state.chosenProducts.findIndex(elem => elem.id === action.payload.productId);
      if (productIndex !== -1){
        var currentProduct =  state.chosenProducts[productIndex];

        const foundProductVariation = findVariationForProductSelection(action.payload.variationsData, currentProduct);
        if(foundProductVariation != null){
          state.chosenProducts[productIndex] = {
            ...state.chosenProducts[productIndex],
            productVariations: action.payload.variationsData.map(variationObj => (mapObjectToIWoocommerceVariation(variationObj))),
            selectedProductVariation: foundProductVariation
          }
        } else {
          state.chosenProducts[productIndex] = {
            ...state.chosenProducts[productIndex],
            productVariations: action.payload.variationsData.map(variationObj => (mapObjectToIWoocommerceVariation(variationObj))),
          }
        }
      }
    }
  },

  setCurrentFee: (state: CalculatorState, action: PayloadAction<number>) => {
    state.currentFee = action.payload
  },

  setCurrentMonthsSelection: (state: CalculatorState, action: PayloadAction<number>) => {
    state.currentMonthsSelection = action.payload
  },
}

const productsSelectionReducer = createReducer(initialState, {
  [actionTypes.ADD_TO_PRODUCT_SELECTION]: (state, action) => manipulators.addItemToSelectedProducts(state, action),
  [actionTypes.UPDATE_PRODUCT_SELECTION]: (state, action) => manipulators.updateItemInSelectedProducts(state, action),
  [actionTypes.REMOVE_PRODUCT_FROM_SELECTION]: (state, action) => manipulators.removeItemFromSelectedProducts(state, action),
  [actionTypes.UPDATE_PRODUCT_VARIATIONS_IN_SELECTION]: (state, action) => manipulators.updateVariationsInChosenProducts(state, action),
  [actionTypes.SET_CURRENT_FEE]: (state, action) => manipulators.setCurrentFee(state, action),
  [actionTypes.SET_CURRENT_MONTHS_SELECTION]: (state, action) => manipulators.setCurrentMonthsSelection(state, action),
  [actionTypes.RESET_ITEMS]: () => initialState,
});

export default function reducer(state = { chosenProducts: [], currentFee: 0, currentMonthsSelection: 24} as CalculatorState, action: PayloadAction<any>) {
  return productsSelectionReducer(state, action);
};