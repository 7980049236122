import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, Firestore } from 'firebase/firestore/lite';
import { AppDispatch } from 'store';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Salty Lemon Calculator web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBB_CbTUJDBzmc0eM5WOi_NlaUdp3fv_2M',
  authDomain: 'salty-lemon-calculator.firebaseapp.com',
  projectId: 'salty-lemon-calculator',
  storageBucket: 'salty-lemon-calculator.appspot.com',
  messagingSenderId: '313268756667',
  appId: '1:313268756667:web:ec6e9e2b55e38837d0099e',
  measurementId: 'G-ED9N13V335'
}


// Initialize Firebase
const app = initializeApp(firebaseConfig)
const firebaseDB = getFirestore(app)

interface FirebaseState {
  all: {
    calculations: {
      fees: number[],
    }
  };
  loadedGetContent: boolean;
  loadingGetContent: boolean;
  errorGetContent: boolean;
}

const initialState: FirebaseState = {
  all: {
    calculations: {
      fees: [],
    }
  },
  loadedGetContent: false,
  loadingGetContent: false,
  errorGetContent: false
}

// *--------------------*
// actionTypes.js Section
// *--------------------*
const GET_FIREBASE_CONTENT = 'GET_FIREBASE_CONTENT'
const GET_FIREBASE_CONTENT_SUCCESS = 'GET_FIREBASE_CONTENT_SUCCESS'
const GET_FIREBASE_CONTENT_FAIL = 'GET_FIREBASE_CONTENT_FAIL'

// *--------------------*
// * reducer.js Section *
// *--------------------*
function requestGetContent(state: FirebaseState) {
  state.loadedGetContent = false;
  state.loadingGetContent = true;
  state.errorGetContent = false;
}

function requestGetContentError(state: FirebaseState, action: PayloadAction<boolean>) {
  state.loadedGetContent = true;
  state.loadingGetContent = false;
  state.errorGetContent = action.payload;
}

const firebaseReducer = createReducer(initialState, {
  [GET_FIREBASE_CONTENT]: (state, action) => requestGetContent(state),
  [GET_FIREBASE_CONTENT_FAIL]: (state, action) => requestGetContentError(state, action),
  [GET_FIREBASE_CONTENT_SUCCESS]: (state, action) => {
    if (action !== undefined) {
      state.all = action.res

      state.loadedGetContent = true
      state.loadingGetContent = false
      state.errorGetContent = false
    }
  },
})

export default function reducer(state = { firebaseService: {} as FirebaseState }, action: PayloadAction<any>) {
  return {
    firebaseService: firebaseReducer(state.firebaseService, action)
  }
}

// *--------------------*
// * actions.js Section *
// *--------------------*
const getCMSContent = () => {
  async function getContent(db: Firestore) {
    let allContent: { calculations: { fees: number[] }} = {
      calculations: {
        'fees': []
      }
    };

    const calculationsDB = collection(db, 'calculations')
    const calculationsDocs = await getDocs(calculationsDB)
    const calculationsArr = calculationsDocs.docs.map(doc => {
      return { id: doc.id, data: doc.data() as {}}
    });
    
    allContent.calculations.fees = calculationsArr?.find(calc => calc?.id === 'fees')?.data as number[];
    return allContent;
  }

  return (dispatch: AppDispatch) => {
    dispatch({
      type: GET_FIREBASE_CONTENT
    })

    getContent(firebaseDB)
      .then((res) => {
        if (res) {
          dispatch({
            type: GET_FIREBASE_CONTENT_SUCCESS,
            res
          })
        }
      })
      .catch((error) => {
        if (error) {
          dispatch({
            type: GET_FIREBASE_CONTENT_FAIL,
            error
          })
        }
      })
  }
}

export { getCMSContent }