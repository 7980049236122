
import React, { FunctionComponent } from "react";
import { useNavigate } from 'react-router-dom';
import styles from "./set-duration.module.scss";
const setDurationImage = require("../../assets/images/howitworks-set-duration.png");


export const InfoSetDuration: FunctionComponent = () => {
  const navigate = useNavigate();
  const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });

  return (
    <div className={styles.howItWorksStep2}>
      <div className={styles.backgroundDiv} />

      <img
        className={styles.maskGroupIcon}
        alt={""}
        src={setDurationImage}
      />

      <div className={styles.sliderDiv}>
        <div className={styles.rectangleDiv} />
        <div className={styles.rectangleDiv1} />
        <div className={styles.rectangleDiv2} />
      </div>
      <div className={styles.setDurationDiv}>Set duration</div>
      <div
        className={styles.findTheBestCombinationOfR}
      >{`Find the best combination of renting duration and price. `}</div>

      <button className={styles.buttonSecondary} onClick={() => handleNavigation("/chooseType", { context: { type: null } }) }>
        <div className={styles.skipDiv}>SKIP</div>
      </button>

      <button className={styles.buttonPrimary} onClick={() => handleNavigation("/howItWorks3", { context: { type: null } }) }>
        <div className={styles.baseDiv}>
          <div className={styles.nextDiv}>NEXT</div>
        </div>
      </button>
    </div>
  );
};