import React from "react";
import { useNavigate } from 'react-router-dom';
import { FunctionComponent } from "react";
import styles from "./choose-gear.module.scss";
const chooseGearImage = require("../../assets/images/howitworks-choose-gear.png");


export const InfoChooseGear: FunctionComponent = () => {
  const navigate = useNavigate();
  const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });

  return (
    <div className={styles.howItWorksResponsive}>
      <div className={styles.backgroundDiv} />
        <img
          className={styles.rectangleIcon}
          alt=""
          src={chooseGearImage}
        />

        <div className={styles.sliderDiv}>
          <div className={styles.rectangleDiv} />
          <div className={styles.rectangleDiv1} />
          <div className={styles.rectangleDiv2} />
        </div>
        <div className={styles.chooseGearDiv}>Choose gear</div>
        <div className={styles.selectFromARangeOfTheBes}>
          Select from a range of the best kites, boards and bars
        </div>

        <button className={styles.buttonSecondary} onClick={() => handleNavigation("/chooseType", {})}>
          <div className={styles.skipDiv}>SKIP</div>
        </button>

        <button className={styles.buttonPrimary} onClick={() => handleNavigation("/howItWorks2", { context: { type: null } }) }>
          <div className={styles.baseDiv} />
          <div className={styles.nextDiv}>NEXT</div>
        </button>
      {/* </div> */}
    </div>
  );
};