import { createSelector } from 'reselect';
import { IWoocommerceProduct } from 'interfaces';
import { mapObjectToIWoocommerceProduct, getProductTypeByTag } from 'store/data-mappings';

export const getAllProducts = createSelector([
  (state) => state.wooCommerce.getAllProducts.loaded,
  (state) => state.wooCommerce.getAllProducts.loading,
  (state) => state.wooCommerce.getAllProducts.error,
  (state) => state.wooCommerce.getAllProducts.all
], (loaded, loading, error, allProducts) => ({
    loaded,
    loading,
    error,
    allProducts: 
      allProducts === undefined ? [] 
      : 
      allProducts.map((product: any) => { 
        if(getProductTypeByTag(product.tags[0]) !== null)
          return mapObjectToIWoocommerceProduct(product);

        return {};
      }
    ) as IWoocommerceProduct[]
  }),
);
