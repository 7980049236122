import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

const Router = ({ routes }: any) => {
  return (
    <BrowserRouter>
      <Routes>
        { routes?.map((route: any, i: number) => {
          return (
            <Route
              key={i}              
              path={route.path}
              element={route.component}
            />
          )
        })}
      </Routes>
    </BrowserRouter>
  )
}

export default Router