import { IWoocommerceVariation, IWoocommerceVariationAttributeType, ProductType, WoocommerceProductAttributeType, WoocommerceProductCategoryType, WoocommerceProductDefaultAttributeType, WoocommerceProductDimensionType, WoocommerceProductImageType, WoocommerceProductMetadataType, WoocommerceProductTagType } from "interfaces";

export function mapObjectToIWoocommerceVariation(variation: any) {
    return ({
      id: variation.id,
      price: variation.price,
      regularPrice: variation.regular_price,
      salePrice: variation.sale_price,
      stockStatus: variation.stock_status,
      image: variation.image,
      attributes: variation.attributes,
      size: variation.attributes.find((attr: IWoocommerceVariationAttributeType) => attr.name.toLowerCase() === "size") != null ? variation.attributes.find((attr: IWoocommerceVariationAttributeType) => attr.name.toLowerCase() === "size")!.option : "",
      color: variation.attributes.find((attr: IWoocommerceVariationAttributeType) => attr.name.toLowerCase() === "color") != null ? variation.attributes.find((attr: IWoocommerceVariationAttributeType) => attr.name.toLowerCase() === "color")!.option : "",
      boardType: variation.attributes.find((attr: IWoocommerceVariationAttributeType) => attr.name.toLowerCase() === "boardtype") != null ? variation.attributes.find((attr: IWoocommerceVariationAttributeType) => attr.name.toLowerCase() === "boardtype")!.option : "",
      lineLength: variation.attributes.find((attr: IWoocommerceVariationAttributeType) => attr.name.toLowerCase() === "linelength") != null ? variation.attributes.find((attr: IWoocommerceVariationAttributeType) => attr.name.toLowerCase() === "linelength")!.option : ""
    } as IWoocommerceVariation);
}

export function mapObjectToIWoocommerceProduct(product: any) {
  return ({
    id: product.id as number,
    metaData: product.meta_data as WoocommerceProductMetadataType[],
    yoast: product.yoast_head_json,
    categories: product.categories as WoocommerceProductCategoryType[],
    name: product.name as string,
    price: parseFloat(product.price as string),
    regularPrice: parseFloat(product.regular_price as string),
    salePrice: parseFloat(product.salePrice as string),
    slug: product.slug as string,
    stockStatus: product.stock_status as string,
    variantIds: product.variations as number[],
    tags: product.tags as WoocommerceProductTagType[],
    purchasable: product.purchasable as boolean,
    relatedProductIds: product.related_ids as number[],
    groupedProductIds: product.grouped_products as number[],
    attributes: product.attributes as WoocommerceProductAttributeType,
    defaultAttributes: product.default_attributes as WoocommerceProductDefaultAttributeType[],
    description: product.description as string,
    dimensions: product.dimensions as WoocommerceProductDimensionType,
    images: product.images as WoocommerceProductImageType[],

    productVariations: product.productVariations,
    productType: getProductTypeByTag(product.tags[0])!,
  });
};

export function getProductTypeByTag(tag: WoocommerceProductTagType): ProductType | null {
  if(tag == null)
    return null;
    
  if(tag.name === "calc__kite"){
    return ProductType.Kite;
  } else if(tag.name === "calc__board"){
    return ProductType.Board;
  } else if(tag.name === "calc__bar"){
    return ProductType.Bar;
  }
  return null;
};