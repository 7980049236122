import { ProductType } from "interfaces";
import React, { FunctionComponent } from "react";
import { useNavigate } from 'react-router-dom';
import styles from "./choose-type.module.scss";

const kiteIcon = require("../../assets/icons/kite.svg").default;
const boardIcon = require("../../assets/icons/board.svg").default;
const barIcon = require("../../assets/icons/bar.svg").default;
const navNextBlackIcon = require("../../assets/icons/navigate-next-black.svg").default;
const navNextYellowIcon = require("../../assets/icons/navigate-next-yellow.svg").default;
const navNextBlueIcon = require("../../assets/icons/navigate-next-blue.svg").default;


export const ChooseType: FunctionComponent = () => {
  const navigate = useNavigate();
  const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });
  
  return (
    <div className={styles.chooseType}>
      <div className={styles.backgroundDiv} />
      <div className={styles.whatWouldYouLikeToChoose}>
        What would you like to choose?
      </div>

      {/* KITES card */}
      <button className={styles.chooseType1} onClick={() => handleNavigation("/chooseProduct", { context: { type: ProductType.Kite } })}>
        <div className={styles.baseDiv}>
          <img
            className={styles.illustrationIcon}
            alt={""}
            src={kiteIcon}
          />
          <div className={styles.nextTypeContainer}>
            <div className={styles.typeName}>Kite</div>
            <img
              className={styles.iconNavigateNext}
              alt={""}
              src={navNextBlackIcon}
            />
          </div>
        </div>
      </button>

      {/* BARS card */}
      <button className={styles.chooseType2} onClick={() => handleNavigation("/chooseProduct", { context: { type: ProductType.Bar } })}>
        <div className={styles.baseDiv1}>
          <img
            className={styles.illustrationIcon}
            alt={""}
            src={barIcon}
          />
          <div className={styles.nextTypeContainer}>
            <div className={styles.typeName}>Bar</div>
            <img
              className={styles.iconNavigateNext}
              alt={""}
              src={navNextBlueIcon}
            />
          </div>
        </div>
      </button>

      {/* BOARDS card */}
      <button className={styles.chooseType3} onClick={() => handleNavigation("/chooseProduct", { context: { type: ProductType.Board } })}>
        <div className={styles.baseDiv2}>
          <img
            className={styles.illustrationIcon}
            alt={""}
            src={boardIcon}
          />
          <div className={styles.nextTypeContainer}>
            <div className={styles.typeName}>Board</div>
            <img
              className={styles.iconNavigateNext}
              alt={""}
              src={navNextYellowIcon}
            />
          </div>
        </div>
      </button>
    </div>
  );
};
