import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useAppSelector } from './custom-redux-types';
import * as firebaseDuck from '../store/firebase.duck';
import { FirebaseFees } from 'interfaces';


export const useFirebaseCMSActions = () => {
  const dispatch = useDispatch()

  const actions = useMemo(
    () => bindActionCreators(
      {
        getCMSContent: firebaseDuck.getCMSContent
      },
      dispatch
    ), [dispatch]
  )

  const getCMSContent = () => actions.getCMSContent()

  return { getCMSContent }
}

export const useFirebaseCMS = () => {

  const firebaseIsLoaded = useAppSelector(
    (state: any) => state.CMS.firebaseService.loadedGetContent
  )

  const fees: FirebaseFees = useAppSelector(
    (state: any) => state.CMS.firebaseService.all === undefined ? 0 : state.CMS.firebaseService.all.calculations.fees
  )
  return { firebaseIsLoaded, fees }
}