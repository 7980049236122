import { IWoocommerceProduct } from 'interfaces';
import { createSelector } from 'reselect';
import { findVariationForProductSelection } from 'utils/product-variation';


export const selectedProductsWithVariations = createSelector([
  (state) => state.calculator.chosenProducts,
], (chosenProducts) => {
  if(chosenProducts == null)
    return [];
  
  return chosenProducts.map((element: IWoocommerceProduct) => {
    const productVariations = element.productVariations && Object.values(element.productVariations);
    if (productVariations == null)
      return element;

    const foundProductVariation = findVariationForProductSelection(productVariations, element);
    if(foundProductVariation == null)
      return element;

    return Object.keys(foundProductVariation).length === 0 ? 
      element 
      :
      {
        ...element,
        selectedProductVariation: foundProductVariation
      } as IWoocommerceProduct;
  });
});

export const chosenProducts = createSelector([
  (state) => state.calculator.chosenProducts
], chosenProducts => chosenProducts);

export const currentFee = createSelector([
  (state) => state.calculator.currentFee
], currentFee => currentFee);

export const currentMonthsSelection = createSelector([
  (state) => state.calculator.currentMonthsSelection
], currentMonthsSelection => currentMonthsSelection);

