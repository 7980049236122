
export enum HexColorCode {
  White = '#FFF',
  white = '#FFF',

  Black = '#000',
  black = '#000',

  Red = '#FF0000',
  red = '#FF0000',

  RubineRed = '#D10056',
  rubineRed = '#D10056',

  Blue = '#0000FF',
  blue = '#0000FF',

  PacificBlue = '#1CA9C9',
  pacificBlue = '#1CA9C9',

  Green = '#008000',
  green = '#008000',

  Yellow = '#FFFF00',
  yellow = '#FFFF00',

  SunsetYellow = '#FFC922',
  sunsetYellow = '#FFC922',

  Orange = '#FFA500',
  orange = '#FFA500',

  Purple = '#800080',
  purple = '#800080',

  Gray = '#808080',
  gray = '#808080',
}