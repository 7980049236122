import api from '../../helpers/wooCommerceAPI';
import * as actionTypes from './actionTypes';
import * as calculatorActionTypes from '../calculator/actionTypes';
import { AppDispatch } from 'store';

// Disable because of User-Agent unsafe header
// async function _recursiveRequest(dispatch, page, stopRecursion, allProducts = []) {
//   await api.get(`products?page=${page}&per_page=50`)
//     .then((res) => {
//       if (res) {
//         if (res?.data?.length !== 50) {
//           stopRecursion = true
//           allProducts = [...allProducts, ...res?.data]
//           dispatch({ 
//             type: actionTypes.GET_ALL_PRODUCTS_SUCCESS,
//             res: allProducts
//           })
//         } else {
//           allProducts = [...allProducts, ...res?.data]
//           page++
//           return _recursiveRequest(dispatch, page, stopRecursion, allProducts)
//         }
//       }
//     })
//     .catch((error) => {
//       if (error) {
//         dispatch({ type: actionTypes.GET_ALL_PRODUCTS_FAIL, error })
//       }
//     })
// }

function loadAllProducts() {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.GET_ALL_PRODUCTS })

    await api.get(`products?page=1&per_page=100`)
    .then((res) => {
      if (res !== undefined) {
        dispatch({ 
          type: actionTypes.GET_ALL_PRODUCTS_SUCCESS,
          payload: res.data
        });
      }
    })
    .catch((error: any) => {
      if (error) {
        dispatch({ type: actionTypes.GET_ALL_PRODUCTS_FAIL, error })
      }
    })

    // Disable because of User-Agent unsafe header
    // await _recursiveRequest(dispatch, 1, false, [])
  }
}


function loadAllVariationsForProductById(productId: number) {
  return async (dispatch: AppDispatch) => {
    await api.get(`products/${productId}/variations?orderby=id&order=asc&per_page=50`)
      .then((res) => {
        if (res !== undefined) {
          dispatch({
            type: actionTypes.UPDATE_PRODUCT_VARIATIONS_IN_ALL,
            payload: { productId: productId, variationsData: res.data },
          });

          // update all products and selected products
          dispatch({
            type: calculatorActionTypes.UPDATE_PRODUCT_VARIATIONS_IN_SELECTION,
            payload: { productId: productId, variationsData: res.data },
          });
        }
      })
      .catch((error: any) => {
        if (error) {
          console.log("Error fetching product variations for product with ID: " + productId);
        }
      })
  }
}

export {
  loadAllProducts,
  loadAllVariationsForProductById
}