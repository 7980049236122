import WooCommerceRestApi from '@woocommerce/woocommerce-rest-api';

const api = new WooCommerceRestApi({
  url: 'https://saltylemonkites.com',
  consumerKey: 'ck_8aa15b9a76bf6417dc8623d1972e78dda2c09c96',
  consumerSecret: 'cs_480f997765e27d6c081090b9a1b4d2900d0b0fb3',
  version: 'wc/v3',
  axiosConfig: { headers: { 'User-Agent': '*' } },
})

if (api['axiosConfig']?.headers) {
  delete api['axiosConfig'].headers['User-Agent']
}

export default api