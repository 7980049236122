import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Customize, DraggableSlider } from 'components';
import { useCalculator } from '../../hooks/calculator';
import generateClass from '../../utils/generateClass';
import '../../styles/globals.scss';
import styles from "./shopping-cart.module.scss";
import { Box } from '@mui/material';

const lemonSqueezerIcon = require('../../assets/icons/lemon-squeezer.svg').default;

export const PackageBuilding = () : JSX.Element => {
  const stylePrefix = 'package-building-';
  const navigate = useNavigate();
  const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });
  const {
    selectedProductsWithVariations,
    currentMonthlyPrice,
    currentRegularPrice,
  } = useCalculator();

  return (
    <>
      <div className={(generateClass(styles, stylePrefix, '-header'))}>
        <div className={(generateClass(styles, stylePrefix, '-header-icon-btn-left'))}>
          <div 
            style={{cursor: 'pointer', marginLeft: '5px'}} 
            onClick={() => { handleNavigation('/', {}) }}
          >
            <svg style={{width: '25px', height: '25px', color: 'rgb(0, 0, 0)'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16"> <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" fill="#000000"></path> </svg>
          </div>
        </div>
        <h2>Salty Kitchen</h2>
        <div></div>
        { /* <div className={generateClass(styles, stylePrefix, '-header-icons-right-wrapper')}></div> */ }
      </div>

      <div className={generateClass(styles, stylePrefix, '-info-container')}>
        <Customize />
        <div style={{marginTop: '20px', marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Box style={{width: '80%'}}>
            <DraggableSlider />
          </Box>
        </div>

        <div className={styles.priceContainer}>
          <div><div style={{display: 'inline', fontWeight: '600', lineHeight: '160%'}}>Total </div>of {selectedProductsWithVariations.length.toString() + " item(s)"}</div>
          <div className={styles.priceUnderline}></div>
          <div className={styles.monthlyPrice}>€ {currentMonthlyPrice} <div style={{display: 'inline', fontSize: 'var(--font-size-sm)'}}>monthly</div></div>
        </div>
        <div className={styles.durationFee}>(incl. duration fee & VAT)</div>
        { currentRegularPrice > 0 ?
          <div className={styles.priceContainer}>
            <div>These products would regulary cost you</div>
            <div className={styles.priceUnderline}></div>
            <div className={styles.regularPrice}>€ {currentRegularPrice}</div>
          </div>
          : <></> 
        }
        
        <button className={styles.submitButton} onClick={() => { handleNavigation('/contactDetails', {}) }}>
          <div className={styles.baseDiv}>
            <div className={styles.nameDiv}>
              <img
                className={styles.iconLemonSqeezer}
                alt=""
                src={lemonSqueezerIcon}
              />
              <div className={styles.requestMyGear}>Request My Gear</div>
            </div>
          </div>
        </button>
      </div>
    </>
  )
};