import * as actionTypes from './actionTypes'
import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import { mapObjectToIWoocommerceVariation } from 'store/data-mappings';


interface WoocommerceState {
  loaded: boolean;
  loading: boolean;
  error: boolean;
  all: any[];
};

const initialState: WoocommerceState = {
  loaded: false,
  loading: false,
  error: false,
  all: []
};

function _getRequest(state: WoocommerceState) {
  state.loaded = false;
  state.loading = true;
  state.error = false;
};

function _errorGetRequest(state: WoocommerceState, action: PayloadAction<boolean>) {
  state.loaded = true;
  state.loading = false;
  state.error = action.payload;
};

const getReducer = createReducer(initialState, {
  [actionTypes.GET_ALL_PRODUCTS]: (state: WoocommerceState) => _getRequest(state),
  [actionTypes.GET_ALL_PRODUCTS_FAIL]: (state: WoocommerceState, action: PayloadAction<boolean>) => _errorGetRequest(state, action),
  [actionTypes.GET_ALL_PRODUCTS_SUCCESS]: (state: WoocommerceState, action: PayloadAction<any[]>) => {
    state.all = action.payload;

    state.loaded = true;
    state.loading = false;
    state.error = false;
  },
  [actionTypes.UPDATE_PRODUCT_VARIATIONS_IN_ALL]: (state: WoocommerceState, action: PayloadAction<{productId: number, variationsData: any[]}>) => {
    // update product variations for product in allProducts if exists
    var productIndex = state.all.findIndex(elem => elem.id === action.payload.productId);
    if (productIndex !== -1){
      state.all[productIndex].productVariations = action.payload.variationsData.map(variationObj => (mapObjectToIWoocommerceVariation(variationObj)));
    }
  }
})

export default function reducer(state = { getAllProducts: {} as WoocommerceState }, action: PayloadAction) {
  return {
    getAllProducts: getReducer(state.getAllProducts, action)
  }
}