import React from 'react';
import generateClass from '../utils/generateClass';
import { IWoocommerceProduct } from 'interfaces';
import style from './swipeable-row.module.scss';
import { useNavigate } from 'react-router-dom';


export const SwipeableRow = (rowData: {currentProducts: IWoocommerceProduct[]}) => {
  const stylePrefix = 'swipable-row-';
  const navigate = useNavigate();

  return (
    <div className={style.scrollWrapper}>
      <div className={generateClass(style, stylePrefix, '-main')} >
        { rowData.currentProducts !== undefined && rowData.currentProducts.length > 0 && rowData.currentProducts.map((currentProduct, i) => {
          return (
            <div 
              key={i.toString() + currentProduct.name}
              onClick={() => {
                navigate('/productDetails', { state: { context: { productDetailView: currentProduct, from: 'chooseProduct' }}});
              }}
              className={style.card}
            >
              <div className={generateClass(style, stylePrefix, '-item-container')}>
                <h5>{currentProduct.name}</h5>
                <div className={generateClass(style, stylePrefix, '-item-content')}>
                  <img src={currentProduct.images[0].src} alt={'Card'}/>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
};