import React, { useState, useEffect, FunctionComponent } from "react";
import Box from '@mui/material/Box';
import styles from "./product-view.module.scss";
import { useNavigate, useLocation } from 'react-router-dom';
import { FeatureType, IWoocommerceProduct, ProductType } from "interfaces";
import { useCalculator, useCalculatorActions, useWooCommerce, useWooCommerceActions } from "hooks";
import { SelectableFeature } from "components";
import { CalculatorBottomBar } from "components";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const plusIcon = require("../../assets/icons/plus-yellow.svg").default;
// const helpIcon = require("../../assets/icons/help.svg").default;
const productDetailBackground = require("../../assets/icons/product-detail-background.svg").default;
const arrowBackIcon = require("../../assets/icons/arrow-back.svg").default;


export const ProductDetailView: FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navFromPage: string = location.state.context.from as string;
  const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });
  
  const { selectedProductsWithVariations } = useCalculator();
  const [productDetailView, setProductDetailView] = useState(location.state.context.productDetailView as IWoocommerceProduct);

  const { allProducts } = useWooCommerce();
  const { loadAllVariationsForProductById } = useWooCommerceActions();

  const [productDetailSizeOptions, setProductDetailSizeOptions] = useState([] as string[]);
  const [productDetailColorOptions, setProductDetailColorOptions] = useState([] as string[]);
  const [productDetailLineLengthOptions, setProductDetailLineLengthOptions] = useState([] as string[]);

  useEffect(() => {
    if (productDetailView.productVariations == null || productDetailView.productVariations.length === 0){
      loadAllVariationsForProductById(productDetailView.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadAllVariationsForProductById, productDetailView]);

  const _findItemDefaultOptionByAttribute = (type: FeatureType, item?: IWoocommerceProduct): any => {
    if(item === undefined)
      return undefined;

    if(type === FeatureType.Size && item.selectedSize != null)
      return item.selectedSize;

    if(type === FeatureType.Color && item.selectedColor != null)
      return item.selectedColor;

    var foundDefault = item.defaultAttributes.find(option => option.name.toLowerCase() === type.toString())
    if (foundDefault !== undefined){
      return foundDefault.option;
    }
    return undefined;
  };  
  const _getDefaultSizeOption = () => {
    return productDetailView.selectedSize ?? (_findItemDefaultOptionByAttribute(FeatureType.Size, productDetailView) ?? productDetailSizeOptions[0] ?? []);
  };
  const _getDefaultColorOption = () => {
    return productDetailView.selectedColor ?? (_findItemDefaultOptionByAttribute(FeatureType.Color, productDetailView) ?? productDetailColorOptions[0] ?? []);
  };
  const _getDefaultLineLengthOption = () => {
    return productDetailView.selectedLineLength ?? (_findItemDefaultOptionByAttribute(FeatureType.LineLength, productDetailView) ?? productDetailLineLengthOptions[0] ?? []);
  };

  function isNullOrWhitespace(testString: string) : boolean {
    if (!testString || testString == null || typeof testString !== 'string' || testString.trim() === ''){
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    var updatedProduct = allProducts.find(elem => elem.id === productDetailView.id);

    if (updatedProduct != null){
      let uniqueSizeOptions = [...new Set(updatedProduct.productVariations?.filter(prodVar => isNullOrWhitespace(prodVar.size) === false && prodVar.attributes.find(attr => attr.name.toLowerCase() === FeatureType.Size.toString())?.option).map(elem => elem.size.toLowerCase()))];
      setProductDetailSizeOptions(uniqueSizeOptions);

      let uniqueColorOptions = [...new Set(updatedProduct.productVariations?.filter(prodVar => isNullOrWhitespace(prodVar.color) === false && prodVar.size === (uniqueSizeOptions.includes(productDetailView.selectedSize) ? productDetailView.selectedSize : uniqueSizeOptions[0])).map(elem => elem.color.toLowerCase()))];
      setProductDetailColorOptions(uniqueColorOptions);

      let uniqueLineLengthOptions = [...new Set(updatedProduct.productVariations?.filter(prodVar => isNullOrWhitespace(prodVar.lineLength) === false && prodVar.attributes.find(attr => attr.name.toLowerCase() === FeatureType.LineLength.toString())?.option).map(elem => elem.lineLength.toLowerCase()))];
      setProductDetailLineLengthOptions(uniqueLineLengthOptions);

      updatedProduct.selectedSize = productDetailView.selectedSize;
      updatedProduct.selectedColor = productDetailView.selectedColor;
      updatedProduct.selectedLineLength = productDetailView.selectedLineLength;
      updatedProduct.selectedProductVariation = productDetailView.selectedProductVariation;
      updatedProduct.selectionId = productDetailView.selectionId;
      setProductDetailView(updatedProduct);
    }

  }, [allProducts, productDetailView]);

  const [isProductInCart] = useState(selectedProductsWithVariations.find((elem: IWoocommerceProduct) => 
    elem.id === productDetailView.id && 
    (elem.selectedProductVariation != null && 
      productDetailView.selectedProductVariation != null ? elem.selectedProductVariation.id === productDetailView.selectedProductVariation.id : false
    )) !== undefined);

  const {
    addItemToSelectedProducts,
    removeItemFromSelectedProducts,
    updateItemInSelectedProducts
  } = useCalculatorActions();

  const handleUpdateOfChosenFeature = (featureType: FeatureType, value: any) => {
    if(featureType === FeatureType.Size){
      productDetailView.selectedSize = value as string;
      let uniqueColorOptions = [...new Set(productDetailView.productVariations?.filter(prodVar => isNullOrWhitespace(prodVar.color) === false && prodVar.size === (productDetailSizeOptions.includes(productDetailView.selectedSize) ? productDetailView.selectedSize : productDetailSizeOptions[0])).map(elem => elem.color.toLowerCase()))];
      setProductDetailColorOptions(uniqueColorOptions);
    } else if(featureType === FeatureType.Color){
      productDetailView.selectedColor = value;
    } else if(featureType === FeatureType.BoardType){
      productDetailView.selectedBoardType = value;
    } else if(featureType === FeatureType.LineLength){
      productDetailView.selectedLineLength = value;
    }
    // update product only if it was already added to the shopping cart
    updateItemInSelectedProducts(productDetailView); 
  };



  return (
    <>
      <img className={styles.arrowBackIcon} alt="" src={arrowBackIcon} style={{cursor: 'pointer'}} onClick={() => { navFromPage === 'shoppingCart' ? handleNavigation('/shoppingCart', {}) : handleNavigation('/chooseProduct', { context: { type: productDetailView.productType } }) }} />
      <div className={styles.productViewDiv}>
        <div className={styles.heroBlockDiv}>
          <div className={styles.imageBlockDiv}>
            <img className={styles.backgroundIcon} alt="" src={productDetailBackground} />
            <div className={styles.imageDiv}>
              <div className={styles.baseDiv}>
                <img className={styles.image36Icon} alt="" src={productDetailView.images[0].src} />
              </div>
            </div>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div className={styles.copyTitleDiv} style={{marginTop: '12px'}}>
            <div className={styles.headerTitle}>{productDetailView.name}</div>
          </div>
          
          <div style={{cursor: 'pointer', marginRight: '25px'}}>
            { isProductInCart === false ?
              <img className={styles.buttonAddProduct} 
                alt="" 
                src={plusIcon}
                onClick={() => {
                  var foundWooProduct = allProducts.find((product) => product.id === productDetailView.id);
                  if (foundWooProduct != null && foundWooProduct.productVariations != null && foundWooProduct.productVariations.length > 0){
                    productDetailView.productVariations = foundWooProduct.productVariations;
                  }
                  addItemToSelectedProducts(productDetailView);
                  handleNavigation('/shoppingCart', {})
                }}
              />
            :
              <Box className={styles.buttonRemoveProduct}
                onClick={() => {
                  removeItemFromSelectedProducts(productDetailView.id, productDetailView.selectionId);
                  handleNavigation('/shoppingCart', {});
                }}>
                <DeleteForeverIcon sx={{ fontSize: "35px", marginTop: "6px", color: "var(--red)" }}/>
              </Box>
            }
          </div>
        </div>

        <div style={{marginBottom: '116px'}}>
          { productDetailSizeOptions.length > 0 ?
            <div className={styles.featureContainer}>
              <div className={styles.flexboxFeatureHeader}>  
                <div className={styles.featureHeader}>Size</div>
                {/* <img className={styles.iconHelp} alt="" src={helpIcon} /> */}
              </div>
              <SelectableFeature
                options={productDetailSizeOptions}
                chosenProduct={productDetailView}
                defaultOption={_getDefaultSizeOption()}
                featureType={FeatureType.Size}
                updateFeatureOnChosenItem={handleUpdateOfChosenFeature}
              />
            </div> : <></>
          }

          { productDetailColorOptions.length > 0 ?
            <div className={styles.featureContainer}>
              <div className={styles.flexboxFeatureHeader}>
                <div className={styles.featureHeader}>Color</div>
                {/* <img className={styles.iconHelp} alt="" src={helpIcon} /> */}
              </div>
              <SelectableFeature
                options={productDetailColorOptions}
                chosenProduct={productDetailView}
                defaultOption={_getDefaultColorOption()}
                featureType={FeatureType.Color}
                updateFeatureOnChosenItem={handleUpdateOfChosenFeature}
              />
            </div> : <></>
          }

          {/* { productDetailView.productType === ProductType.Board && 
            selectableProductOptions.boardType.options.length > 0 ?
            <div className={styles.featureContainer}>
              <div className={styles.flexboxFeatureHeader}>
                <div className={styles.featureHeader}>Board Type</div>
                  // <img className={styles.iconHelp} alt="" src={helpIcon} /> 
              </div>
              <SelectableFeature
                options={selectableProductOptions.boardType.options}
                chosenProduct={productDetailView}
                defaultOption={selectableProductOptions.boardType.defaultOption ?? ''}
                featureType={FeatureType.BoardType}
                updateFeatureOnChosenItem={handleUpdateOfChosenFeature}
              />
            </div> : <></>
          } */}

          { productDetailView.productType === ProductType.Bar &&
            productDetailLineLengthOptions.length > 0 ?
            <div className={styles.featureContainer}>
              <div className={styles.flexboxFeatureHeader}>
                <div className={styles.featureHeader}>Line Length</div>
                {/* <img className={styles.iconHelp} alt="" src={helpIcon} /> */}
              </div>
              <SelectableFeature
                options={productDetailLineLengthOptions}
                chosenProduct={productDetailView}
                defaultOption={_getDefaultLineLengthOption()}
                featureType={FeatureType.LineLength}
                updateFeatureOnChosenItem={handleUpdateOfChosenFeature}
              />
            </div> : <></>
          }

          <div className={styles.featureContainer}>
            <div className={styles.flexboxFeatureHeader}>
              <div className={styles.featureHeader}>Description</div>
              {/* <img className={styles.iconHelp} alt="" src={helpIcon} /> */}
            </div>
            <div className={styles.productDescription}>
              <div dangerouslySetInnerHTML={{ __html: productDetailView.description }} />
            </div>
          </div>
        </div>

        <CalculatorBottomBar />
      </div>
    </>
  );
};