import { useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { IWoocommerceProduct, ProductType } from 'interfaces';
import { useAppSelector } from './custom-redux-types';

import { actions as wooCommerceActions, selectors as wooCommerceSelectors } from '../store/wooCommerce/index';

export const useWooCommerceActions = () => {
  const dispatch = useDispatch()

  const actions = useMemo(
    () => bindActionCreators(
      {
        getAllProducts: wooCommerceActions.loadAllProducts,
        loadAllVariationsForProductById: wooCommerceActions.loadAllVariationsForProductById
      },
      dispatch
    ), [dispatch]
  )

  const getAllProducts = useCallback(() => {
    actions.getAllProducts()
    // eslint-disable-next-line
  }, [])

  const loadAllVariationsForProductById = useCallback((id: number) => {
    actions.loadAllVariationsForProductById(id);
    // eslint-disable-next-line
  }, []);
  
  return {
    getAllProducts,
    loadAllVariationsForProductById
  }
};


export const useWooCommerce = () => {
  const { loaded, loading, error, allProducts } = useAppSelector(state => wooCommerceSelectors.getAllProducts(state));

  function _filterCategoryBySlug(slug: string, exactlyMatch: boolean) {
    return allProducts.filter((product: IWoocommerceProduct) => {
      if(product.categories == null){
        return false;
      } else {
        return (
          exactlyMatch === true ? 
            product.categories[0].slug === slug :
            product.categories[0].slug.includes(slug)       
        )}
      }
    )
  }

  function _filterProductsByType(selectedType: ProductType) {
    return allProducts.filter((product: IWoocommerceProduct) => {
      if(product.productType == null)
        return false;

      if(product.productType === selectedType)
        return true;

      return false;
    });
  }

  function _findProductById(productId: number) {
    return allProducts.find((product: IWoocommerceProduct) => {
      if(product.productType == null || product.id !== productId)
        return false;

      return true;
    });
  }

  // Some products doesn't have tags configured, so we find by slug in this case.
  const additionalAccessories = _filterCategoryBySlug('additional-accessories', true)
  const wetsuits = _filterCategoryBySlug('wetsuit', false)
  const harnessSeats = _filterCategoryBySlug('harness', false)
  const boardBags = _filterCategoryBySlug('board-bag', true)

  const currentProductsByType = (typeFromContext: ProductType|undefined|null) => typeFromContext == null ? [] : _filterProductsByType(typeFromContext);
  const currentProductById = (productId: number) => _findProductById(productId);

  return { 
    loaded,
    loading,
    error,
    allProducts,
    additionalAccessories,
    wetsuits,
    harnessSeats,
    boardBags,
    currentProductsByType,
    currentProductById
  }
}
