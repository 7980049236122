import React, { FunctionComponent } from "react";
import styles from "./success-screen.module.scss";
import { useNavigate } from 'react-router-dom';
const lemonIcon = require('../../assets/icons/lemon.svg').default;


export const SuccessScreen: FunctionComponent = () => {
  const navigate = useNavigate();
  const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });

  return (
    <div className={styles.successScreenDiv}>
      <div className={styles.centeredContent}>
        <div className={styles.successHeader}> Thank you for the order!</div>
        
        <div className={styles.weAreCheckingYourRequestA}>
          We will review your request and come back to you shortly with an indicative offer.
        </div>
        
        <div className={styles.yourSaltyLemonTeam}>Your Salty Lemon Team</div>
        <img className={styles.lemonIcon} alt="" src={lemonIcon} />
      </div>

      <div className={styles.bottomContainer}>
        <div className={styles.goBackLemonShake}>{`Go back & Lemon Shake`}</div>
        <button className={styles.button} onClick={() => { handleNavigation('/shoppingCart', { context: { type: null }}) }}>
          <div className={styles.baseDiv}>
            <div className={styles.backToWebsite}>Back To Shopping Cart</div>
          </div>
        </button>
      </div>
    </div>
  );
};