import React, { useEffect } from 'react';
import { SwipeableRow } from '../../components/swipeable-row';
import { IWoocommerceProduct, ProductType } from 'interfaces';
import { useNavigate, useLocation } from 'react-router-dom';
import { useWooCommerce, useWooCommerceActions } from '../../hooks/wooCommerce';
import { CalculatorBottomBar } from 'components';
import { CircularProgress } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import styles from "./choose-product.module.scss";
import generateClass from '../../utils/generateClass';
const arrowBackIcon = require("../../assets/icons/arrow-back.svg").default;


export const ChooseProduct = () : JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();
    const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });

    const stylePrefix = 'package-building-';
    const typeFromContext: ProductType = location.state.context.type;
    
    const { allProducts, loaded, loading, currentProductsByType } = useWooCommerce();
    const { loadAllVariationsForProductById } = useWooCommerceActions();

    const [currentGearTag, setCurrentGearTag] = React.useState('');
    const handleChange = (
      event: React.MouseEvent<HTMLElement>,
      gearFilter: string,
    ) => {
        setCurrentGearTag(gearFilter);
    };

    // pre-load product variations for selected product category
    useEffect(() => {
        if (!loading && loaded) {
            var products = currentProductsByType(typeFromContext) as IWoocommerceProduct[];
            products.forEach((value: IWoocommerceProduct) => {
                if (value.productVariations == null || value.productVariations.length === 0){
                    loadAllVariationsForProductById(value.id);
                }
            });
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [loadAllVariationsForProductById, allProducts]);

    const calcTagPreSeason = 'calc__preSeasonGear';
    const filterProductsByGear = () => {
        let productsByType = currentProductsByType(typeFromContext) as IWoocommerceProduct[];
        return currentGearTag === calcTagPreSeason ? 
        productsByType.filter(product => product.tags.map(tag => tag.name).includes(calcTagPreSeason) === true) : 
        productsByType.filter(product => product.tags.map(tag => tag.name).includes(calcTagPreSeason) === false);
    };

    return (
        <>
            <img className={styles.arrowBackIcon} alt="" src={arrowBackIcon} style={{cursor: 'pointer'}} onClick={() => { handleNavigation("/chooseType", { context: { type: null } }) }} />

            <div className={generateClass(styles, stylePrefix, '-info-container')}>
            { (loading && !loaded) ? 
                <CircularProgress className={styles.centered} />
                : 
                <>
                { typeFromContext != null ? 
                    <div>
                        { (location.state.context.type === ProductType.Kite) && 
                            <h4>Choose a kite</h4>
                        }
                        { (location.state.context.type === ProductType.Board) && 
                            <h4>Choose a board</h4>
                        }
                        { (location.state.context.type === ProductType.Bar) &&
                            <h4>Choose a bar</h4>
                        }
                        <div style={{margin: '10%', textAlign: 'center'}}>
                        <ToggleButtonGroup
                            color="primary"
                            value={currentGearTag}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                            >
                            <ToggleButton value="">Latest Gear</ToggleButton>
                            <ToggleButton value="calc__preSeasonGear">Pre-Season Gear</ToggleButton>
                        </ToggleButtonGroup>
                        </div>
                        <SwipeableRow currentProducts={filterProductsByGear()} />
                    </div>
                    : <></>
                }
                </>
            }
            </div>
            <CalculatorBottomBar />
        </>
    );
};