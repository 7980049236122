import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as calculatorActions, selectors as calculatorSelectors } from '../store/calculator';
import { IWoocommerceProduct } from 'interfaces';
import { useAppSelector } from './custom-redux-types';


const useCalculatorActions = () => {
  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(
      {
        addItemToSelectedProducts: calculatorActions.addItemToSelectedProducts,
        updateItemInSelectedProducts: calculatorActions.updateItemInSelectedProducts,
        removeItemFromSelectedProducts: calculatorActions.removeItemFromSelectedProducts,
        setCurrentFee: calculatorActions.setCurrentFee,
        setCurrentMonthsSelection: calculatorActions.setCurrentMonthsSelection,
        resetItems: calculatorActions.resetItems,
      },
      dispatch
    ), [dispatch]
  )

  const addItemToSelectedProducts = (productDetailView: IWoocommerceProduct) => {
    // if(productDetailView.productVariations == null){
    //   console.error("Cannot add product to chosenProducts - Product variations is null for product with ID: " + productDetailView.id );
    // }
    actions.addItemToSelectedProducts(productDetailView);
  }

  const selectedProductsWithVariations: IWoocommerceProduct[] = useAppSelector(state => calculatorSelectors.selectedProductsWithVariations(state));
  const removeItemFromSelectedProducts = (productId: number, selectionId: number) => {
    const selectedProduct = selectedProductsWithVariations.find(elem => elem.selectionId === selectionId)!;
    if(selectedProduct != null){
      actions.removeItemFromSelectedProducts(productId, selectionId);
    }
  }

  const updateItemInSelectedProducts = (productDetailView: IWoocommerceProduct) => {
    actions.updateItemInSelectedProducts(productDetailView);
  }

  const setCurrentFee = (fee: number) => actions.setCurrentFee(fee);
  const setCurrentMonthsSelection = (months: number) => actions.setCurrentMonthsSelection(months);
  const resetItems = () => actions.resetItems();

  return {
    addItemToSelectedProducts,
    removeItemFromSelectedProducts,
    updateItemInSelectedProducts,
    setCurrentFee,
    setCurrentMonthsSelection,
    resetItems,
  }
}



const useCalculator = () => {
  const selectedProductsWithVariations: IWoocommerceProduct[] = useAppSelector(state => calculatorSelectors.selectedProductsWithVariations(state));
  const currentFee: number = useAppSelector(state => calculatorSelectors.currentFee(state));
  const currentMonthsSelection = useAppSelector(state => calculatorSelectors.currentMonthsSelection(state));

  // current price is the monthly payable price for the equipment (dependent on the duration)
  const currentMonthlyPrice = useMemo(() => {
    let monthlyPrice: number = 0;
    if(selectedProductsWithVariations.length >= 1){
      selectedProductsWithVariations.forEach(product => {
        monthlyPrice += (product.selectedProductVariation != null ? Number(product.selectedProductVariation.price) : 0);
      });
      if(monthlyPrice === 0)
        return monthlyPrice;
      else
        monthlyPrice += currentFee;
    }
    return monthlyPrice;

  // eslint-disable-next-line
  }, [
    selectedProductsWithVariations,
    currentFee,
    currentMonthsSelection
  ])

  // regular price is an indication of how much the package would be normally worth
  const currentRegularPrice = useMemo(() => {
    let regularPrice = 0;
    selectedProductsWithVariations.forEach(product => {
      regularPrice += (product.selectedProductVariation != null ? Number(product.selectedProductVariation.regularPrice) : 0);
    });
    return regularPrice;
  // eslint-disable-next-line
  }, [
    selectedProductsWithVariations
  ]);

  return {
    selectedProductsWithVariations,
    currentMonthlyPrice,
    currentMonthsSelection,
    currentRegularPrice,
  }
}

export { useCalculatorActions, useCalculator };