import React, { FunctionComponent } from "react";
import { useNavigate } from 'react-router-dom';
import styles from "./enjoy-kiting.module.scss";
const enjoyKitingImage = require("../../assets/images/howitworks-enjoy-kiting.png");


export const InfoEnjoyKiting: FunctionComponent = () => {
  const navigate = useNavigate();
  const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });
  
  return (
    <div className={styles.howItWorksStep3}>
      <div className={styles.backgroundDiv} />

      <img 
        className={styles.maskGroupIcon} 
        alt={""} 
        src={enjoyKitingImage} 
      />

      <div className={styles.sliderDiv}>
        <div className={styles.rectangleDiv} />
        <div className={styles.rectangleDiv1} />
        <div className={styles.rectangleDiv2} />
      </div>
      <div className={styles.enjoyKitingDiv}>Enjoy kiting!</div>
      <div className={styles.getTheGearShippedToYourD}>
        Get the gear shipped to your door
      </div>

      <button className={styles.buttonPrimary} onClick={() => handleNavigation("/chooseType", { context: { type: null } }) }>
        <div className={styles.baseDiv} />
        <div className={styles.getStartedDiv}>get started</div>
      </button>
    </div>
  );
};