import React from "react";
import styles from "./calculator-bottom-bar.module.scss";
import { useNavigate } from 'react-router-dom';
import { useCalculator } from "hooks/calculator";
import { DraggableSlider } from "components/draggable-slider";

const shoppingCartIcon = require("../assets/icons/shopping-cart.svg").default;
const redEllipseIcon = require("../assets/icons/ellipse-red.svg").default;


export const CalculatorBottomBar = () : JSX.Element => {
    const navigate = useNavigate();
    const handleNavigation = (pathname: string, state: any) => navigate(pathname, { state });
    
    const {
        currentMonthlyPrice,
        selectedProductsWithVariations
    } = useCalculator();

    return (
        <div className={styles.bottomBarDiv}>
            <div className={styles.durationSlider}>
                <DraggableSlider />
            </div>

            <div className={styles.shoppingCartDiv} onClick={() => {handleNavigation('/shoppingCart', { context: { type: null } })}}>
                <button className={styles.shoppingCartButton}>
                    <div className={styles.monthlyPrice}>€ {currentMonthlyPrice} <div style={{fontSize: '9px', fontWeight: '0'}}>monthly</div></div>
                    <div>
                        <img
                        className={styles.iconshoppingCart}
                        alt=""
                        src={shoppingCartIcon}
                        />
                        <div className={styles.circleDiv}>
                            <img className={styles.ellipseIcon} alt="" src={redEllipseIcon} />
                            <div className={styles.countShoppingCart}>{selectedProductsWithVariations.length}</div>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    );
}